/* App.css */
body, html {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    background-color: black;

}

.App {
  background:black;
  text-align: center;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
}

.BackgroundContainer {
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.2s ease-in-out;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding-top: 8px;
}
.header-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.header-icon img {
  width: 24px;
  height: 24px;
}
.header-icon-button {
  /* background: linear-gradient(180deg,
        rgba(88, 85, 214, 1) 0%,
        #38378A 100%); */
        background-color: rgba(52, 51, 136, 0.5);
        backdrop-filter: blur(5px); 
}
/* .header-icon-button.all-nft {
    background: linear-gradient(to bottom, #FF9502 0%, #FF4C03 100%);
} */
.header-icon {
  margin: 0 8px;
}
.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin-and-title {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  /* Adjust the gap as needed */
}

.coin {
  width: 40px;
  /* Adjust the size as needed */
  height: 40px;
  /* Adjust the size as needed */
}



@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255,76,3, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 149, 2, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}
.footer {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(5px);
  background: linear-gradient(180deg,
      rgba(88, 85, 214, 0) 0%,
      rgba(88, 85, 214, 0.5) 50%,
      #38378A 100%);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px; */
}

.footer-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: linear-gradient(to top, #FF1E03 0%, #FF4C03 100%);
  animation: pulse-red 2s infinite;
  /* Add this line */
}

.footer-icon img {
  width: 32px;
  height: 32px;
}
.bullet-button-container {
  padding: 4px;
  display: flex;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  margin: 4px 0;
}
.bullet-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: auto;}
.bullet-button-overlay {
  position: absolute;
  z-index: 10;
}
.bullet-button-overlay p {
  font-size: 20px;
}
.bullet-image{
  max-width: 24px;
}
.bullet-image.transparent {
  opacity: 0.5;
}
h1 {
  font-family: 'Brixton', sans-serif;
  font-size: 50px;
  font-weight: 400;
  margin: 0px;
  padding: 0;
  line-height: 1;
}
h2 {
  margin: 0;
}
p {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
}
/* .tokens-label {
  padding: 2px;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  max-width: fit-content;
  margin-top: 0;
} */

/* .token-gain {
  display: flex;
  flex-direction: column;
  gap: 5px;
} */

/* .stats {
  margin-top: 20px;
} */

.buttons {
  display: flex;
}

button {
  background: linear-gradient(to bottom, #FF9502 0%, #FF4C03 100%);  
  border: none;
  padding: 10px 20px;
  border-radius: 32px;
  width: 100px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.big-button {
  width: auto !important
}
.orange {
  color: #FF9502;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  z-index: 1000;
}

.popup-content {
  background-color: rgba(52, 51, 136, 0.7);
  backdrop-filter: blur(10px);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 50%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  animation: slideUp 0.5s ease-out forwards;
}

.popup.closing .popup-content {
  animation: slideDown 0.5s ease-out forwards;
}

.popup-close-icon {
  fill: white;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
}
.popup h2{
  font-family: 'Brixton', sans-serif;
  font-size: 32px;
  font-weight: 400;
}
.popup button {
    animation: pulse-orange 2s infinite;
    margin-top: 8px;
}
.pulse-orange-button {
  animation: pulse-orange 2s infinite;

}
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.close-button {
  margin-top: 20px;
}
.BackgroundImage0 {
  background-image: url('https://bot.ethernity.game/new-bg.jpg');
}

.BackgroundImage1 {
  background-image: url('https://bot.ethernity.game/transparent.png');
}

.BackgroundImage2 {
  background-image: url('https://bot.ethernity.game/muzzle-new.png');
}
.BackgroundImage3 {
  background-image: url('https://bot.ethernity.game/4.jpeg');
}

.invite-logo {
  width: 48px;
}

.invite-logo-placeholder {
  width: 48px;
  height: 48px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.bg-blur {
  background-color: #12122cd5;
  backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.bg-blur.no-blur { 
  backdrop-filter: none;
}

.invite-stats-container {
  background: #38378a61;
  padding: 8px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invite-stats-container.no-bg {
  background: none;
  backdrop-filter: none;
}
.invite-stats-container.no-bg .invite-stats-line p:nth-child(2) {
  display: none;;
}

.invite-stats {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  margin-bottom: 8px;
}
.invite-stats div {
  min-width: 128px;
}
.invite-stats h2 {
  font-family: 'Brixton', sans-serif;
  font-size: 48px;
  font-weight: 400;
  margin: 0;
}
.invite-stats h3 {
  font-size: 16px;
  margin: 0;
  color: #FF9502;
}
.invite-stats-line {
    display: flex;
    flex-direction: row;
    justify-content: centers;
    align-items: center;
    gap: 8px;
    margin: 2px 0;
}

.invite-stats-line p:nth-child(1) {
    background: linear-gradient(180deg,
            rgba(88, 85, 214, 1) 0%,
            #38378A 100%);
    padding: 4px 8px;
    border-radius: 4px;
}
.invite-stats-line p:nth-child(2) {
  background: linear-gradient(180deg,
            rgba(88, 85, 214, 1) 0%,
            #38378A 100%);
  width: 32px;;
  padding: 4px 8px;
  border-radius: 4px;
}
.invite-stats-line p:nth-child(3) {
  width: 64px;
  text-align: right;
}
.invite-stats-line p:nth-child(4) {
  color: #FF9502;
}
.invite-button {
    animation: pulse-orange 2s infinite;
    margin-top: 8px;
    margin-bottom: 64px;
}
.quest-button {
    animation: pulse-orange 2s infinite;
    margin-bottom: 64px;
}
.help-button {
  animation: pulse-orange 2s infinite;
  margin-bottom: 16px;
  margin-top: 16px;
}
.quests-page {
  width: 100%;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quests-page h1 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.quests-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quest {
  background: linear-gradient(180deg,
            rgba(88, 85, 214, 1) 0%,
            #38378A 100%);
  max-width: 100%;
  display: flex;
  justify-content: left;
  padding: 8px;
  margin: 0 16px;
  gap: 8px;
  border-radius: 8px;
}
.quest:nth-last-child(1) {
  margin-bottom: 64px;
}
.quest:active {
    background: rgba(88, 85, 214, 1);
}

.quest.one-time {
  background: linear-gradient(to bottom, #FF9502 0%, #FF4C03 100%);
}
.quest.one-time:active {
  background: #FF9502;
}

.quest-content {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  text-align: left;
}
.quest-icon {
  width: 64px;
  height: 65px;
  border-radius: 80px;
  overflow: hidden;
  background: black;
}
.quest-icon img {
  width: 64px;
  height: 64px;
}
.glow-text {
  color: yellow;
  text-shadow: 0 0 15px yellow, 0 0 25px yellow;
  animation: glow 1.5s linear infinite;
}
.quests-notification {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.quests-notification p {
  line-height: 24px;
}


@keyframes glow {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}


.floating-point-container {
  position: absolute;
  height: 200px;
  top:-150px;
  left: calc(50% + 100px);
}

.floating-point {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: floatUp 2s ease-in-out forwards;
  opacity: 1;
  font-weight: 400;
  font-family: 'Brixton', sans-serif;
  font-size: 32px;
  color: #FF9502; 
}

@keyframes floatUp {
  0% {
    bottom: 0;
    opacity: 1;
  }

  100% {
    bottom: 100%;
    opacity: 0;
  }
}

.lds-ripple {
  /* change color here */
  color: #fff
}
.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}


.help-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 100vh;
}
.help-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}
.help-container p {
  line-height: 20px;
  margin: 0;
  font-size: 14px;
  
}
.help-element {
  margin: 0 16px;
  text-align: left;
  padding: 4px 8px;
  background-color: #38378a7b;
  border-radius: 8px;
}
.wallet-container {
  background-color: rgba(52, 51, 136, 0.4);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 16px;
  max-width: fit-content;
  overflow: hidden;
  backdrop-filter: blur(5px);
  margin-bottom: 4px;
}
.wallet-container p {
  font-size: 12px
}
.wallet-name {
  padding-left: 4px;
  padding-right: 2px;
}
.wallet-label {
    background-color: rgba(52, 51, 136, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    border-radius: 16px;
}
.wallet-connected {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-left: 4px;
  background: #4cd964;
  box-shadow: 0 0 4px #4cd964;
}
.wallet-connected.true {
    background: #4cd964;
    box-shadow: 0 0 4px #4cd964;
}
.wallet-connected.false {
  background: #ff3b30;
  box-shadow: 0 0 4px #ff3b30;
}
.footer-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(52, 51, 136, 0.5);
  backdrop-filter: blur(10px);
}

.footer-bar.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.footer-bar-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  width: 20%;
  position: relative;
}
.footer-bar-text {
  font-size: 12px;
  margin: 0;
  color: white;
}
.footer-bar-icon {
  width: 24px;
  height: 24px;
  fill: white;
}
  
.footer-bar-tab.active > .footer-bar-icon {
 fill:#FF9502;
}
.footer-bar-tab.active>.footer-bar-text {
  color: #FF9502;
}
.footer-label {
  line-height: 16px;
}


.nft-page {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}
.nft-page .header, .help-page .header {
  align-items: center;
  padding: 8px 0;
}
.nft-page .header h1,
.help-page .header h1 {
  margin-bottom: -8px;
}
.nft-container {
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  /* Adjust the gap size as needed */
  margin-bottom: 16px;
}

.nft-card {
  width: calc(50% - 8px);
  min-height: 256px;
  box-sizing: border-box;
}

.flip-card {
  perspective: 1000px;
  /* Add perspective to the flip card container */
}

.flip-card-inner {
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  border-radius: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  /* Safari */
}

.flip-card-front {
  overflow: hidden;
    display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.flip-card-back {
  overflow: hidden;
    background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  color: white;
  transform: rotateY(180deg);
}
.flip-card-front {
  opacity: 1;
  transition: opacity 0.8s;
}
.flip-card.flipped .flip-card-front {
  opacity: 0;
}
.nft-attributes-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
    display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}
.nft-attributes {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  text-align: left;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
}
.nft-attributes p {
  font-size: 12px;
  line-height: 14px;
  width: auto;
  margin: 2px 0;
}
.nft-attributes p:first-child {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
}
.flip-card-inner.legendary {
  border: 2px solid #FF9502;
  box-shadow: 0 0 4px #FF9502;
}
.flip-card-inner.mythical {
  border: 2px solid #FF4C03;
  box-shadow: 0 0 4px #FF4C03;
}
.flip-card-inner.epic {
  border: 2px solid rgba(88, 85, 214, 1);
  box-shadow: 0 0 4px rgba(88, 85, 214, 1);
}
.flip-card-inner.rare {
  border: 2px solid #007AFF;
  box-shadow: 0 0 4px #007AFF;
}
.flip-card-inner.uncommon {
  border: 2px solid #4cd964;
  box-shadow: 0 0 4px #4cd964;
}
.flip-card-inner.unknown {
  border: 2px solid #ccc;
  box-shadow: 0 0 4px #ccc;
}
.nft-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 32px
}
.nft-card-header p {
  font-size: 12px;
  backdrop-filter: blur(5px);
  padding: 2px 4px;
  border-radius: 4px;
  margin: auto;
}
.sell-opensea {
  width: 24px;
  height: 24px;
  margin: 4px;
}
.logo-opensea {
    width: 24px;
    height: 24px;
}
.burn-nft {
    width: 24px;
    height: 24px;
    margin: 4px;
    background: #FF4C03;
    backdrop-filter: blur(5px); 
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.nft-button-use {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 100%;
  background: linear-gradient(360deg,
        rgb(42, 41, 96) 0%,
        #38378A 100%);  padding: 8px 0;
}
.nft-button-use.used {
    background: linear-gradient(to bottom, #FF9502 0%, #FF4C03 100%);
}
.nft-card-body {
  width: 100%;
}
.nft-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
}
.nft-info p {
  font-size: 10px;
  backdrop-filter: blur(5px);
  padding: 2px;
  border-radius: 4px;
}


.nft-button-use.equipped {
  background: linear-gradient(to bottom, #FF9502 0%, #FF4C03 100%);
}

.home-tokens 
{
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}
.footer-label, .tokens-label {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  padding: 2px;
  margin: 2px;
  backdrop-filter: blur(5px);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}
.tokens-label{
  margin: 0;
  margin-top: -4px;
}
.nft-card.no-overflow {
  position: relative;
  overflow: visible;
}

.nft-card-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc(100% + 4px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-top: 4px solid rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  z-index: 100;
  border-radius: 8px;
}

.nft-help-container {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(52, 51, 136, 0.5);
    margin: 8px;
    margin-bottom: 64px;
    padding: 16px 0;
    border-radius: 8px;
}
.nft-help-container-text {
    text-align: left;
}
.nft-help-container p {
  font-size: 14px;
  display: inline-block;
}
.nft-help-container h2 {
  font-family: 'Brixton', sans-serif;
  margin: 0;
  font-size: 28px;
  line-height: 28px;
  font-weight: 400;
}
p.time-counter {
  font-size: 16px;
}
p.time-counter > span {
    display: inline-block;
      margin: 0 2px;
}
.footer-bar-tab.quests {
  position: relative;
}
.quests-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #FF4C03;
  top: 0;
  margin-right: -32px;
}
.nft-notification {
    display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 12px;
      width: auto;
      padding: 0 2px;
      height: 16px;
      border-radius: 4px;
      background: #FF4C03;
      top: 0;
      margin-right: -32px;
      animation: pulse-red 2s infinite;
}
.nft-button-use.listed{
  background: linear-gradient(to bottom, #5856D6 0%, #38378A 100%);
}
.nft-price-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
.header-icon-svg {
  fill: white;
  width: 24px;
  height: 24px;
}
.nft-page.list {
  padding-bottom: 48px;
}
.all-nft {
      background: linear-gradient(to bottom, #FF9502 0%, #FF4C03 100%);
}